<template>
  <div :style="{ backgroundColor: mainColor }">
    <!-- App Bar -->
    <header class="app-bar">
      <div class="back-button">
        <router-link to="/" class="back-link">
          <span>&larr; Back</span>
        </router-link>
      </div>
    </header>

    <!-- Song Container -->
    <div class="song-container" v-if="song">
      <!-- Thumbnail -->
      <h1 class="song-title">{{ song.song_title }}</h1>
      <div class="thumbnail">
        <img
          :src="thumbnailUrl"
          :alt="song.song_title"
          class="thumbnail-image"
        />
      </div>
      <div class="platform-links">
        <!-- YouTube Icon -->
        <a
          v-if="song.youtube_url"
          :href="song.youtube_url"
          target="_blank"
          rel="noopener noreferrer"
          class="platform-icon"
        >
          <i class="fab fa-youtube"></i>
        </a>
        <!-- TikTok Icon -->
        <a
          v-if="song.tik_tok_url"
          :href="song.tik_tok_url"
          target="_blank"
          rel="noopener noreferrer"
          class="platform-icon"
        >
          <i class="fab fa-tiktok"></i>
        </a>
      </div>
      <!-- Player Controls -->
      <div class="player">
        <audio
          ref="audioPlayer"
          :src="songDownloadUrl"
          @timeupdate="updateCurrentTime"
          @loadedmetadata="setDuration"
        ></audio>

        <div class="slider-container">
          <input
            type="range"
            class="slider"
            min="0"
            :max="duration"
            step="1"
            v-model="currentTime"
            @input="seek"
            :style="{ background: sliderBackground }"
          />
        </div>

        <div class="time-info">
          <span>{{ formatTime(currentTime) }}</span>
          <span>{{ formatTime(duration) }}</span>
        </div>
        <button @click="togglePlay">{{ isPlaying ? "Pause" : "Play" }}</button>
      </div>

      <!-- Download Button with Forced Download -->
      <div class="download-button-container">
        <a @click.prevent="triggerDownload" class="download-button">
          <button>Download song</button>
        </a>
      </div>
    </div>

    <!-- Loading -->
    <div v-else></div>
  </div>
</template>

<script setup>
import { getDownloadUrl, getSongById, getThumbnailUrl } from "@/api/services";
import { computed, onMounted, ref } from "vue";
import { useRoute } from "vue-router";

const route = useRoute();
const songId = computed(() => route.params.songId);

const song = ref(null);
const thumbnailUrl = ref(null);
const songDownloadUrl = ref(null);
const audioPlayer = ref(null);

// Player state
const isPlaying = ref(false);
const currentTime = ref(0);
const duration = ref(0);

const mainColor = "#1A1A1D"; // Define your main color

// Slider background styling
const sliderBackground = computed(() => {
  const progress = (currentTime.value / duration.value) * 100 || 0;
  return `linear-gradient(to right, #1A1A1D, #605EA1 ${progress}%, #ccc ${progress}%)`;
});

// Fetch song details
onMounted(async () => {
  document.body.style.backgroundColor = "#1A1A1D";
  song.value = await getSongById(songId.value);
  songDownloadUrl.value = getDownloadUrl(song.value.file_name);
  thumbnailUrl.value = getThumbnailUrl(song.value.thumbnail_name);
});

// Function to force the download
function triggerDownload() {
  const filename = `DJ Chris - ${song.value.song_title}.wav`;

  // Create a Blob for the file
  fetch(songDownloadUrl.value)
    .then((response) => response.blob())
    .then((blob) => {
      // Create an Object URL for the Blob
      const url = URL.createObjectURL(blob);
      // Create a link element to trigger the download
      const a = document.createElement("a");
      a.href = url;
      a.download = filename; // Set the custom filename
      document.body.appendChild(a); // Append the link to the DOM
      a.click(); // Trigger the download
      document.body.removeChild(a); // Clean up by removing the link
      URL.revokeObjectURL(url); // Revoke the object URL to free up memory
    })
    .catch((error) => console.error("Error downloading file:", error));
}

// Player controls
async function togglePlay() {
  if (!audioPlayer.value) return;

  try {
    if (isPlaying.value) {
      await audioPlayer.value.pause();
      isPlaying.value = false;
    } else {
      await audioPlayer.value.play();
      isPlaying.value = true;
    }
  } catch (error) {
    console.error("Error during playback:", error);
  }
}

function updateCurrentTime() {
  if (audioPlayer.value) {
    currentTime.value = Math.floor(audioPlayer.value.currentTime);
  }
}

function setDuration() {
  if (audioPlayer.value) {
    duration.value = Math.floor(audioPlayer.value.duration);
  }
}

function seek() {
  if (audioPlayer.value) {
    audioPlayer.value.currentTime = currentTime.value;
  }
}

function formatTime(seconds) {
  const mins = Math.floor(seconds / 60)
    .toString()
    .padStart(2, "0");
  const secs = (seconds % 60).toString().padStart(2, "0");
  return `${mins}:${secs}`;
}
</script>

<style scoped>
.app-bar {
  background-color: var(--mainColor);
  padding: 20px;
  color: #1a1a1d;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.back-button {
  display: flex;
  align-items: center;
}

.back-link {
  color: white;
  text-decoration: none;
}

.song-title {
  font-size: 24px;
  color: white;
  text-align: center;
}

.song-container {
  padding: 20px;
}
.thumbnail-image {
  width: 100%; /* Make it responsive up to 100% of the container's width */
  max-width: 400px; /* Set a maximum width */
  height: auto; /* Keep aspect ratio */
  max-height: 300px; /* Optional: Set maximum height */
  object-fit: cover; /* Ensure the image covers the container without stretching */
  border-radius: 8px;
  display: block; /* Make it a block element */
  margin-left: auto; /* Center horizontally */
  margin-right: auto; /* Center horizontally */
}

.player {
  margin-top: 20px;
  text-align: center;
}

.slider-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem 0;
}
.slider {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 8px;
  border-radius: 10px;
  outline: none;
  cursor: pointer;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 16px;
  height: 16px;
  background: #fff;
  border: 2px solid #605ea1;
  border-radius: 50%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  position: relative;
}

.slider::-webkit-slider-thumb:hover {
  transform: scale(1.2);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

/* Thumb Style */
.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 16px;
  height: 16px;
  background: #fff;
  border: 2px solid #605ea1;
  border-radius: 50%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}

.slider::-webkit-slider-thumb:hover {
  transform: scale(1.2);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.slider::-moz-range-thumb {
  width: 16px;
  height: 16px;
  background: #fff;
  border: 2px solid #605ea1;
  border-radius: 50%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}

.slider::-moz-range-thumb:hover {
  transform: scale(1.2);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

/* Active Track Style */
.slider:hover {
  background: linear-gradient(135deg, black, #605ea1);
}

.time-info {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  color: white;
}
.download-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem; /* Adjust spacing as needed */
}
button {
  background: linear-gradient(135deg, #605ea1, #ff758c);
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

button:hover,
.download-button button:hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

button:active,
.download-button button:active {
  transform: translateY(0);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background: linear-gradient(135deg, #ff758c, #ff7eb3);
}
/* Container for icons */
.platform-links {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  gap: 1.5rem; /* Space between icons */
}

/* Icon Links */
.platform-icon {
  color: white; /* Default icon color */
  font-size: 2rem; /* Icon size */
  transition: transform 0.3s ease, color 0.3s ease;
}

/* Hover Effect */
.platform-icon:hover {
  color: #ff758c; /* Change color on hover */
  transform: scale(1.2); /* Enlarge on hover */
}

/* TikTok-specific styling */
.platform-icon .fa-tiktok {
  color: #010101; /* TikTok logo color */
}

/* YouTube-specific styling */
.platform-icon .fa-youtube {
  color: #ff0000; /* YouTube red color */
}
</style>
