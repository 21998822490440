<template>
  <div>
    <router-view></router-view>
    <!-- This should be where the dynamic content goes -->
  </div>
</template>

<style scoped>
.background-main {
  background-color: #1a1a1d;
}

.appbar {
  background-color: var(--mainColor);
  padding: 20px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center; /* Centers horizontally */
}
</style>
