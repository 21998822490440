// router/index.js
import HomeView from "@/components/HomeView.vue"; // Adjust the path as needed
import SongDetails from "@/components/SongDetails.vue"; // Adjust the path as needed
import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    props: true, // Makes the route params available as props in SongDetails
  },
  {
    path: "/song-details/:songId",
    name: "song-details",
    component: SongDetails,
    props: true, // Makes the route params available as props in SongDetails
  },
  // Other routes...
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
