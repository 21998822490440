// src/main.js (or main.ts if you're using TypeScript)
import { createApp } from "vue";
import App from "./App.vue";
import { firebaseApp } from "./firebase"; // Firebase initialization
import router from "./router"; // Make sure this imports your router

const app = createApp(App);
// Add Firebase to your Vue instance (optional)
app.config.globalProperties.$firebase = firebaseApp;
app.use(router); // Use the router
app.mount("#app");
