// Import the functions you need from the SDKs
import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";

// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAxDQksg82L-VFRAR5nSDTx3aeINwy4Hck",
  authDomain: "dj-chris-b9731.firebaseapp.com",
  projectId: "dj-chris-b9731",
  storageBucket: "dj-chris-b9731.firebasestorage.app",
  messagingSenderId: "61933959878",
  appId: "1:61933959878:web:4130e587b91d2a9787b5c7",
  measurementId: "G-VF34JZJ6ZR",
};

// Initialize Firebase app
const firebaseApp = initializeApp(firebaseConfig);

// Initialize Analytics
const analytics = getAnalytics(firebaseApp);

// Export initialized Firebase app and other services
export { analytics, firebaseApp };
