// src/api/services.js
import { supabase } from "../lib/supabaseClient";

// Fetch a song by its ID
export async function getSongById(songId) {
  try {
    // Use Supabase's 'from' to query the 'songs' table
    const { data, error } = await supabase
      .from("songs") // Name of the table
      .select("*") // Select all fields (you can be more specific if needed)
      .eq("id", songId) // Filter by the song ID
      .single(); // Get only one record (since IDs are unique)

    if (error) {
      console.error("Error fetching song:", error.message);
      return null;
    }

    return data; // Return the song data
  } catch (error) {
    console.error("Error fetching song:", error);
    return null;
  }
}

// Fetch song's thumbnail image
export function getThumbnailUrl(thumbnailName) {
  console.log("Getting thumbnail url for ", thumbnailName);
  const response = supabase.storage
    .from("thumbnails")
    .getPublicUrl(thumbnailName);

  console.log(response.data["publicUrl"]);

  return response.data["publicUrl"];
}

// Fetch song's download URL
export function getDownloadUrl(songName) {
  console.log("Getting download url for ", songName);
  const response = supabase.storage.from("songs").getPublicUrl(songName);

  console.log(response.data["publicUrl"]);

  return response.data["publicUrl"];
}
